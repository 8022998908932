import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { useLocation, Link, Route, Switch } from 'wouter'
import { Welcome } from './components/Welcome'
import { NewEntry } from './components/NewEntry'
import { Entries } from './components/Entries'
// import reportWebVitals from './reportWebVitals'

import { Button, MantineProvider } from '@mantine/core'

function App() {
  return (
    <div>
      <header>
        <Link to={'/'}>
          <Button>Test home</Button>
        </Link>
        <Link to={'welcome'}>
          <Button>Test welcome</Button>
        </Link>
        <Link to={'entries'}>
          <Button>Test entries</Button>
        </Link>
        <Link to={'newEntry'}>
          <Button>Test newEntry</Button>
        </Link>
      </header>
      <Switch>
        <Route path="/">Flurpp</Route>
        <Route path="/welcome/:user" component={Welcome}></Route>
        <Route path="/newEntry" component={NewEntry}></Route>
        <Route path="/entries" component={Entries}></Route>
        <Route>Not found</Route>
      </Switch>
    </div>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <MantineProvider>
      <App />
    </MantineProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
