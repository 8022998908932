import { Title } from '@mantine/core'
import { FC } from 'react'

interface WelcomeProps {
  params: any
}

export const Welcome: FC<WelcomeProps> = ({ params: { user } }) => {
  console.log('yes', user)
  const now = new Date()
  return (
    <>
      <Title order={2}>
        Welcome {user}, right now it's {humanDate(now)}
      </Title>
    </>
  )
}

function humanDate(date: Date): string {
  const dayName = date.toLocaleDateString('en', { weekday: 'long' })
  const monthName = date.toLocaleDateString('en', {
    month: 'long',
    day: 'numeric',
  })

  return `${dayName}, ${monthName}, ${date.getFullYear()}`
}
